<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" fixed="top" :class="{ 'scrolled': showScrollTop }">
        <b-navbar-brand to="/">
          Osha Handyman Service
        </b-navbar-brand>
    
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/">Home</b-nav-item>
            <b-nav-item to="/quote">Request A Quote</b-nav-item>
            <b-nav-item to="/payments">Payments</b-nav-item>
            <b-nav-item to="/privacy-policy">Privacy Policy</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ms-auto" right>
            <b-nav-item href="tel:7866648891" class="call"> 
              <b-icon icon="telephone-outbound-fill"></b-icon> 
              <span >(786) 664-8891</span>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="main-view">

      <router-view/>
    </div>
    <Transition name="fade">
      <nav v-if="showScrollTop" class="scroll-to-top" @click="onGoToTop()">
        <b-icon icon="chevron-up"></b-icon> 
      </nav>
    </Transition>
    <footer>
      <span>&copy;{{currentYear}} Osha Handyman Services</span>
      <a href="tel:7866648891" class="call">
        <span >give us a call (786) 664-8891</span>
      </a>
    </footer>
  </div>
</template>
<script>
  export default {
    data() {
      const currentYear =  new Date().getFullYear();
      return {
        currentYear,
        showScrollTop: false
      }
    },
    mounted() {
      window.addEventListener("scroll", () => {
        const doc = document.documentElement;
        // const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
        const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        this.showScrollTop = top >= 57;
      });
    },
    methods: {
      onGoToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  }
</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.scroll-to-top {
  position: fixed;
  right: 4px;
  bottom: 48px;
  background: var(--osha-secondary-dark);
  border: 1px solid var(--osha-dark);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  color: var(--osha-text-color);
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
  
  .b-icon {
    top: 12px;
    left: 12px;
    position: absolute;
  }
}

nav.navbar {
  
  background: var(--osha-dark) !important;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: height .2s;

  a.navbar-brand {
    color: var(--osha-text-color) !important;
  }
  border-bottom: 1px solid var(--osha-secondary-dark) !important;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
} 

.call * {

  color: var(--osha-primary);
}

.call .b-icon {
  font-size: 1.4rem !important;
  padding-right: 8px;
  transform: translateY(2px);
}

a.call  {
  text-decoration: var(--osha-primary) underline;
}

.main-view {
  padding-top: 57px;
  min-height: calc(100vh - 64px);
}

footer {
  min-height: 32px;
  padding: 12px;
  background: var(--osha-secondary-dark);
  margin-top: 16px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  ::v-deep 
  &.navbar {
    padding: 8px 16px;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: var(--osha-primary) !important;
    }
  }
}

</style>
