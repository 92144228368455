import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { 
  BootstrapVue, 
  IconsPlugin, 
  NavbarPlugin, 
  JumbotronPlugin,  
  CardPlugin, 
  ButtonPlugin, 
  FormPlugin, 
  FormDatepickerPlugin,
  FormTimepickerPlugin,
  FormFilePlugin,
  FormTextareaPlugin
} from 'bootstrap-vue';
import Vuelidate from 'vuelidate'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/global.css';

[
  BootstrapVue,
  JumbotronPlugin,
  IconsPlugin,
  NavbarPlugin,
  CardPlugin,
  ButtonPlugin,
  FormPlugin,
  FormDatepickerPlugin,
  FormTimepickerPlugin,
  FormFilePlugin,
  FormTextareaPlugin,
  Vuelidate
].forEach(plugin => Vue.use(plugin));

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
