<template>
  <div class="home">
    <main>
      <b-jumbotron>
        <template #header>
          <div class="banner-header">
            Osha Handyman Service
          </div>
        </template>
    
        <template #lead>
          From Floor to Roof We Do it all. 
          <div>
            <a href="tel:7866648891" class="call">
              <span >give us a call (786) 664-8891</span>
            </a>
          </div>
        </template>
    
        <p>
          Providing quality service to South Florida.  
        </p>
        <div class="button-container">
          <b-button to="/quote" class="highlight-button">Get A Free Quote</b-button>
        </div>
      </b-jumbotron>
      <section class="content">
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Handyman Services"
            :img-src="handymanServices"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
          <b-card-text>
            We can handle just about any business or home repair you need. We can guide you on your project.
          </b-card-text>
      
          <b-button to="/quote/general-services" variant="primary">Get a Quote</b-button>
        </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Kitchen Remodeling"
            :img-src="kitchenRemodel"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class="m"
          >
            <b-card-text>
              backsplashes, floors, granite, marble, plumbing, counter-tops, custom work, installations, sinks and more
            </b-card-text>
        
            <b-button to="/quote/kitchen-remodeling" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Bathroom Remodeling"
            :img-src="bathroomRemodel"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
            <b-card-text>
              vanities, flooring, shower heads, plumbing, toilet install and repair, light fixtures, tile, grout and more
            </b-card-text>
        
            <b-button to="/quote/bathroom-remodeling" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Door Installation"
            :img-src="doorInstall"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
            <b-card-text>
              interior doors,
              exterior doors,
              sliding doors,
              closet doors,
              custom installation,
              standard installation,
              steel doors, repairs, and more
            </b-card-text>
        
            <b-button to="/quote/door-installation" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Window Installation"
            :img-src="windowInstall"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
            <b-card-text>
              standard installation,
              repair,
              framing, 
              custom installation,
              impact windows, 
              blinds and more

            </b-card-text>
        
            <b-button to="/quote/window-installation" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Roofing"
            :img-src="roofing"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
            <b-card-text>
              flat roofs,
              standard roofs,
              plywood,
              shingle,
              terracotta roofs,
              repair, installation and more
            </b-card-text>
        
            <b-button to="/quote/roofing" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Painting"
            :img-src="painting"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
            <b-card-text>
              interior painting,
              exterior painting,
              walls and ceilings,
              repairs,
              power-wash and more
            </b-card-text>
        
            <b-button to="/quote/painting" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
        <div class="card-wrapper">
          <b-icon icon="award-fill"></b-icon>
          <b-card
            title="Drywall/Repair"
            :img-src="drywall"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class=""
          >
            <b-card-text>
              plaster, 
              sanding, 
              repair, 
              installation,
              taping,
              spackle and more
            </b-card-text>
        
            <b-button to="/quote/dry-wall" variant="primary">Get a Quote</b-button>
          </b-card>
        </div>
      </section>
    </main>
  </div>
</template>
<style lang="scss" scoped>
  .home {
    ::v-deep .jumbotron {
      padding: 2.5rem;
      min-height: calc(45vh - 57px);
      background:  url('@/assets/tools.svg'), linear-gradient(to right, rgba(37,57,68,1.0) 80%, rgba(37,57,68,.85)), url('@/assets/tools.svg'), rgba(37,57,68,1.0);
      background-size: contain contain, 20%, contain;
      background-repeat: no-repeat;
      background-position: center right 7%, top left,  bottom right -5%, top left;
      color: var(--osha-text-color);
  
      .banner-header {
        color: var(--osha-primary);
      }
  
    }

    section.content {
      max-width: 80vw;
      margin-top: 64px;
      margin-left: auto;
      margin-right: auto;
      padding: 2rem 2rem 2rem 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-rows: 1fr;
      gap: 2rem;
      .card-wrapper {
        position: relative;
        border-radius: 8px;
        overflow: hidden;

        & > .b-icon {
          color: var(--osha-primary-light);
          position: absolute;
          top: 7px;
          right: 7px;
          z-index: 3;
          font-size: 1.2rem;
        }

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 60px 60px 0;
          border-color: transparent var(--osha-secondary-dark) transparent transparent;
          right: 0;
          top: 0;
          position: absolute;
        }
      }
      ::v-deep {
        article {

          .btn-primary {
            background: var(--osha-accent);
            border-color: transparent;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .home {
      section.content {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        padding: 1rem 0;
        max-width: calc(80vw - 2rem);
      }
    }
  }
  
  @media screen and (max-width: 1199px) {
    .home {
      ::v-deep .jumbotron {
        background: linear-gradient(to right, rgba(37,57,68,1.0) 60%, rgba(37,57,68,.65)), url('@/assets/tools.svg'), rgba(37,57,68,1.0);
        background-size: contain, contain, cover !important;
      }

      section.content {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        padding: 1rem 0;
        max-width: calc(100vw - 2rem);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home {
      ::v-deep .jumbotron {
        background-size: cover;
      }

      section.content {
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        padding: 0 0;
        max-width: calc(100vw - 2rem);
        div {
          margin: 0 auto;
        }
      }
    }
  }
</style>

<script>
import handymanServices from '@/assets/grinder-cutting.webp';
import kitchenRemodel from '@/assets/kitchen-remodel.webp';
import bathroomRemodel from '@/assets/bathroom-remodel.webp';
import doorInstall from '@/assets/door-install.webp';
import flooring from '@/assets/flooring.webp';
import roofing from '@/assets/roofing.webp';
import painting from '@/assets/painting.webp';
import drywall from '@/assets/drywall.webp';
import windowInstall from '@/assets/window-install.webp';

export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      handymanServices,
      kitchenRemodel,
      bathroomRemodel,
      flooring,
      doorInstall,
      windowInstall,
      roofing,
      painting, 
      drywall
    }
  }
}
</script>
